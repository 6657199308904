module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5MLBHX7","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby","env":"development","ga4MeasurementId":"G-3CD8ZFGQ7F","analyticsApiTrackUrl":"https://staging-api.maxer.io/analytics/track"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby_route_changed"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"maxer.io","short_name":"maxer.io","start_url":"/","background_color":"#ffffff","theme_color":"#635bff","display":"minimal-ui","icon":"src/assets/logo.svg","icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8f0fffcedc7c3d34cd13bc0993f44a2a"},
    }]
