/* eslint-disable react/prop-types */
import React from "react";
import TopLayout from "./TopLayout";
import RestrictForEmployees from "./RestrictForEmployees";
// import LogRocket from "logrocket";

export const wrapRootElement = ({element, props}) => {

  // import("firebase/storage");
  console.log("activeEnv browser ", process.env.GATSBY_ACTIVE_ENV);
  if (process.env.GATSBY_ACTIVE_ENV === "staging") {
    return (
      <RestrictForEmployees>
        <TopLayout>{element}</TopLayout>
      </RestrictForEmployees>
    );
  }
  return (
    <TopLayout>{element}</TopLayout>
  );
};

// export const onClientEntry = () => {
//   if (process.env.GATSBY_ACTIVE_ENV === "production") {
//     /* Only init LR in Prod */
//     LogRocket.init("ccat9n/momonzo");
//   }
// };
