import React from "react";
import PropTypes from "prop-types";

export default function RestrictForEmployees(props) {
  const [isFetching, setIsFetching] = React.useState(true);
  const [blockAccess, setBlockAccess] = React.useState(false);

  React.useEffect(() => {
    fetch(
      "https://us-central1-momonzo-development.cloudfunctions.net/http_has_whitelisted_ip"
    )
      .then((response) => response.json())
      .then((result) => {
        const {is_whitelisted} = result;
        setBlockAccess(!is_whitelisted);
        setIsFetching(false);
      })
      .catch((error) => console.log("error:", error));
  }, []);

  if (isFetching) {
    return <div>Loading staging environment...</div>;
  }

  return (
    <React.Fragment>
      {blockAccess ? <div>No access allowed</div> : props.children}
    </React.Fragment>
  );
}

RestrictForEmployees.propTypes = {
  children: PropTypes.node,
};
