import React from "react";
import PropTypes from "prop-types";
import {FirebaseContext} from "./context";

function refsAreEqual(prevRef, newRef) {
  const bothNull = !prevRef && !newRef;

  // Note: isEqual is a built-in firebase method: https://firebase.google.com/docs/reference/js/firebase.database.Query#isequal
  const equal = !!prevRef && !!newRef && prevRef.isEqual(newRef);
  return bothNull || equal;
}

export function useFetchFirestore(firestoreRef, options) {
  const {listen, type, stopLoading, transformResult } = (options || {});

  const [value, setValue] = React.useState(type === "collection" ? [] : null);
  const [loading, setLoading] = React.useState(true);
  const [refChanged, setRefChanged] = React.useState(false);
  const ref = React.useRef(firestoreRef);

  React.useEffect(() => {
    if (!refsAreEqual(firestoreRef, ref.current)) {
      ref.current = firestoreRef;
      setRefChanged(true);
    } else {
      setRefChanged(false);
    }
  }, [firestoreRef]);

  const firebase = React.useContext(FirebaseContext);
  const currentRef = ref.current;

  React.useEffect(() => {
    setLoading(true);
    if (!currentRef) {
      setValue(type === "collection" ? [] : null);
      setRefChanged(false)
    } else if (Boolean(listen)) {
      const unsubscribe = currentRef
        .onSnapshot(snap => {
          setValue(firebase.formatSnapshot(snap));
          setLoading(false);
          setRefChanged(false)
        });
      return () => unsubscribe();
    } else {
      currentRef
        .get()
        .then(snap => {
          setValue(firebase.formatSnapshot(snap));
          setLoading(false);
          setRefChanged(false)
        });
    }
  }, [
    currentRef,
    refChanged, // NOTE: This dep is not used but forces a rerender on each ref change. DON'T remove.
    listen,
    firebase,
    type
  ]);

  // Transform the result if transformResult prop exists
  const result = transformResult ? transformResult(value) : value;

  // If 'stopLoading' prop is true then return loading as false
  return [result, (loading && !stopLoading)];
}

useFetchFirestore.propTypes = {
  firestoreRef: PropTypes.object,
  options: PropTypes.object,
};
