import {
  red,
  green,
  yellow,
  amber,
  indigo,
  pink,
  purple,
  deepPurple,
} from "@material-ui/core/colors";

const theme = {
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: "none",
        "&:focus": {
          boxShadow: "none",
        },
      },
      // fab: {
      //   boxShadow: 'none',
      //   '&:focus': {
      //     boxShadow: 'none',
      //   },
      // },
      root: {
        textTransform: "none",
      },
    },
    MuiLink: {
      root: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
      },
    },
    MuiPaper: {
      elevation1: {
        border: "1px solid rgba(0, 0, 0, 0.08)",
        boxShadow: "0 3px 3px 0 rgba(0,0,0,.01)",
        // borderRadius: "0.8rem",
      },
    },
    MuiExpansionPanel: {
      root: {
        "&:before": {
          backgroundColor: "none",
        },
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          background: "#fff"
        }
      }
    }
  },
  palette: {
    primary: {
      main: indigo["A400"], // In hex: "#3D5AFE"
      light: indigo["A200"],//"#5469d4",
      dark: indigo["A700"],
      contrastText: "#fff",
    },
    secondary: {
      main: pink[400], //"#47B881",
      light: pink[500], //"#80cbc4",
      dark: pink[600], //"#00695c",
      contrastText: "#fff",
    },
    statusLabelOpacity: 0.2,
    borders: "rgba(0, 0, 0, 0.08)",
    error: {
      main: red[500],
    },
    warning: {
      main: amber[600],
    },
    background: {
      default: "#fff",
      light: "#f9f9f9", //"rgba(0, 0, 0, 0.04)"
      dark: "#2c3e50",
    },
    statusColors: {
      // Project / Freelancer application:
      start: purple[500],
      draft: deepPurple[500],
      pending: amber[600],
      paymentRequested: amber[600],
      paymentFailed: red[500],
      paid: green[500],
      earnings: green[500],
      submitted: pink[500],
      canceled: pink[500],
      setupBillingMethod: pink[500],
      hired: green[500],
      approved: green[500],
      active: green[500],
      stopped: red[500],
      revoked: red[500],
      removed: red[500],
      expired: red[500],
      fees: red[500],
      overtime: amber[600],
      finished: green[900],
      // Other
      comingSoon: yellow[700],
      // Freelancer requests
      open: deepPurple[500],
      // Deliverables
      planned: pink[500],
      done: green[500],

      // Freelancer application only:
      accepted: green[500],
      rejected: red[500],
      // General:
      error: red[500],
    },
    solutions: {
      hire: {
        light: "#ffe57f",
        dark: amber[600],
      },
      manage: {
        light: deepPurple["A100"],
        dark: deepPurple[600],
      },
      evaluate: {
        light: "#B9F4BC",
        dark: "#14B775",
      },
    },
  },
  typography: {
    fontFamily: '"Noto Sans", sans-serif',
    fontSize: 14,
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 400,
    fontWeightBold: 700,
  },
};

export default theme;
