// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-free-consult-index-jsx": () => import("./../../../src/pages/free-consult/index.jsx" /* webpackChunkName: "component---src-pages-free-consult-index-jsx" */),
  "component---src-pages-free-consult-requested-jsx": () => import("./../../../src/pages/free-consult/requested.jsx" /* webpackChunkName: "component---src-pages-free-consult-requested-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-signup-jsx": () => import("./../../../src/pages/signup.jsx" /* webpackChunkName: "component---src-pages-signup-jsx" */),
  "component---src-pages-teams-index-jsx": () => import("./../../../src/pages/teams/index.jsx" /* webpackChunkName: "component---src-pages-teams-index-jsx" */),
  "component---src-pages-teams-profile-jsx": () => import("./../../../src/pages/teams/profile.jsx" /* webpackChunkName: "component---src-pages-teams-profile-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

