import React from "react";
import {FirebaseContext} from "./context";

function constructApi(firebase) {
  const firestore = firebase.firestore();
  const auth = firebase.auth();
  const storage = firebase.storage();
  return {
    // Features
    storage,
    auth,
    firestore,

    // Convenience methods
    createTimestamp: (date) =>
      firebase.firestore.Timestamp.fromDate(date || new Date()),
    emailAuthProvider: firebase.auth.EmailAuthProvider,
    /* Social Sign In Method Provider */
    // this.googleProvider = new app.auth.GoogleAuthProvider();
    // this.facebookProvider = new app.auth.FacebookAuthProvider();

    // API references
    lead: (email) => firestore.doc(`leads/${email}`),
    leads: () => firestore.collection("leads"),
    user: (userId) => firestore.doc(`users/${userId}`),
    users: () => firestore.collection("users"),
    userProfilePhoto: (userId) => storage.ref(`users/${userId}/profile-photo`),
    bill: (uid) => firestore.doc(`bills/${uid}`),
    bills: () => firestore.collection("bills"),
    session: (uid) => firestore.doc(`sessions/${uid}`),
    sessions: () => firestore.collection("sessions"),
    team: (uid) => firestore.doc(`teams/${uid}`),
    teams: () => firestore.collection("teams"),
    teamProfilePhoto: (teamId) => storage.ref(`teams/${teamId}/profile-photo`),
    teamApplication: (uid) => firestore.doc(`teamApplications/${uid}`),
    teamApplications: () => firestore.collection("teamApplications"),
    contract: (uid) => firestore.doc(`contracts/${uid}`),
    contracts: () => firestore.collection(`contracts`),
    contractVersion: (uid) => firestore.doc(`contractVersions/${uid}`),
    contractVersions: () => firestore.collection(`contractVersions`),
    hireContract: (uid) => firestore.collection('hireContracts').doc(uid),
    hireContracts: () => firestore.collection(`hireContracts`),
    business: (uid) => firestore.doc(`businesses/${uid}`),
    businesses: () => firestore.collection("businesses"),
    review: (uid) => firestore.doc(`reviews/${uid}`),
    reviews: () => firestore.collection("reviews"),
    request: (uid) => firestore.doc(`requests/${uid}`),
    requests: () => firestore.collection("requests"),
    portfolioItem: (uid) => firestore.doc(`portfolioItems/${uid}`),
    portfolioItems: () => firestore.collection("portfolioItems"),
    portfolioItemPhoto: (userId, photoId) => storage.ref(`users/${userId}/portfolio/${photoId}`),
    billingDetail: (uid) => firestore.doc(`billingDetails/${uid}`),
    billingDetails: () => firestore.collection(`billingDetails`),
    userFeedback: () => firestore.collection("userFeedback"),

    // Helpers
    formatSnapshot: (snapshot, includeRef) =>
      Boolean(snapshot.docs)
        ? snapshot.docs.map((doc) => formatDoc(doc, includeRef))
        : formatDoc(snapshot, includeRef),
  };
}

function formatDoc(doc, includeRef) {
  return Boolean(doc.exists)
    ? {uid: doc.id, ref: includeRef ? doc.ref : null, ...doc.data()}
    : null;
}

export function FirebaseProvider(props) {
  const firebaseApi = props.firebase ? constructApi(props.firebase) : null;
  return (
    <FirebaseContext.Provider value={firebaseApi}>
      {props.children}
    </FirebaseContext.Provider>
  );
}
