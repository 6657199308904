import React from "react";
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import theme from "../../src/constants/theme";
import {FirebaseProvider, credentials} from "../../src/components/Firebase";

// Use singleton pattern to avoid duplicate firebase instances
let firebaseInstance;
const initializeFirebase = firebase => {
  if (firebaseInstance) {
    return firebaseInstance
  }
  firebase.default.initializeApp(credentials);
  firebaseInstance = firebase.default;
  return firebaseInstance;
};

export default function TopLayout(props) {

  const [firebase, setFirebase] = React.useState(null);

  React.useEffect(() => {
    if (!firebase && typeof window !== "undefined") {
      const app = import("firebase/app");
      const auth = import("firebase/auth");
      const firestore = import("firebase/firestore");
      const storage = import("firebase/storage");
      // const analytics = features.analytics ? import("firebase/analytics") : null

      Promise.all([
        app,
        auth,
        firestore,
        storage,
      ]).then(values => {
        setFirebase(initializeFirebase(values[0]))
      })
    }
  }, [firebase]);

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap" rel="stylesheet"/>
      </Helmet>
      <ThemeProvider theme={createMuiTheme(theme)}>
        <CssBaseline/>
        <FirebaseProvider firebase={firebase}>
          {props.children}
        </FirebaseProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
